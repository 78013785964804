import Translation from "./ItalianClientTranslation.json"
export default{
    data(){
        return{
            translation:Translation
        }
        
    },
    methods: {
        translateToItalian(key,quizId){
            let language = quizId == 2492 ? "It" : "en";
            if(language=='en' && this.translation[key])
        return this.translation[key]['English'];
      else if(language=='It' && this.translation[key])
        return this.translation[key]['Italian'];
    else return key
        }
    },


}